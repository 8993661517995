<template>
  <div class="home">
   
    <div class="newrelease d-flex justify-content-center">
      <b-card   v-if="newRelease != null" bg-variant="light" border-variant="secondary"
         title="Latest Release"
         :img-src="newRelease.items[0].images[0].url"
            :img-alt="newRelease.items[0].name"
            img-top
            style="max-width: 30rem"
            tag="article"
            class="m-4 text-center"
         >
         <b-card-text>
              {{newRelease.items[0].name}}
          </b-card-text>
          <b-button :href="newRelease.items[0].external_urls.spotify" variant="dark" target="_blank">Listen</b-button>
         </b-card>
  </div> 

      <div class="cash d-flex justify-content-center">
        <b-carousel
      id="carousel-1"
      :interval="3000"
      controls
      indicators
      img-width="720"
      img-height="480"
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide
        caption="Cash plays piano"
        img-src="../assets/cash_plays_piano.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Early mornings"
        img-src="../assets/homePhoto.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Hair"
        img-src="../assets/RHslide1.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Get your merch!"
        img-src="../assets/RHslide2.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        caption="New beats on the way"
        img-src="../assets/RHslide3.jpg"
      ></b-carousel-slide>
    </b-carousel>
    <!-- <img src="../assets/cash_plays_piano.jpg">
    <img src="../assets/homePhoto.jpg"> -->
    </div> 

  </div> 

  
</template>




<script>
import Spotify from "node-spotify-api"


const spotify = new Spotify({
  id: "d37e32659d4f4a02b1f65a8b841214c1",
  secret: "98da41f097534d99872f1140fd08b1ec"
});


export default {
  name: 'Home',
  data(){
    return{
      newRelease : null
    }
  },
mounted () {
  spotify.request(`https://api.spotify.com/v1/artists/6nyRfYMXUpuOAgl2x2GSwU/albums`)
    .then((data) => {
      // console.log(data);
      data.items.sort((a,b) =>{
        return  new Date(b.release_date).getTime() -  new Date(a.release_date).getTime();
      }); 
      this.newRelease = data;
    })
  },
  methods:{
  }
};
</script>

<style scoped>

.carousel{
  margin: auto;
  max-width: 80% !important;
}

 /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }

</style>
