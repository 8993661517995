<template>
<div class="app">
  <mainnavbar/>
  <div class="spacing"></div>
  <router-view/>
  <mainfooter/> 
</div>
</template>

<script>
import Mainfooter from './components/Mainfooter.vue';
import Mainnavbar from './components/Mainnavbar.vue';


export default {
  components: { Mainfooter, Mainnavbar },
  name: "app",
    Mainfooter
};
</script>

<style>
.fill { 
    min-height: 100%;
    height: 100%;
}

body{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black !important;
  
  height: 100% !important; 
}

.spacing{
  height: 300px;
  background-color: rgb(0, 0, 0);
  /* background-image: url("assets/homePhoto.jpg"); */
}

 /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }

</style>
