<template>
    <b-navbar class="nav" toggleable="lg" type="dark" variant="dark" fixed="top">
        <b-navbar-brand>
            <b-img alt="logo" src="../assets/RHheader.png" height="150" ></b-img>
          </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        

        <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="main-navbar">
                <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link>
                <router-link to="/beatstars">BeatStars</router-link>
                <router-link to="/discography">Discography</router-link>
                <router-link to="/contact">Contact</router-link>
                <a href="https://teespring.com/stores/rahsaanhakeem" target="_blank">Apparel</a>
        </b-navbar-nav>
        </b-collapse>
  </b-navbar>
</template>

<script>

export default {
  name: "Mainnavbar",
watch: {
          '$route' () {
                const element = document.querySelector("#nav-collapse");
                let isShown = element.classList.contains("show");
                if(isShown){
                    this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
                }
            }
        }
};
</script>

<style scoped>

.navbar-dark .navbar-nav .nav-link{
      color:black!important;
    }
.main-navbar.router-link,a{
    padding-left: 50px;
    padding-right: 50px;
    color: white;
    
}

.main-navbar.router-link:hover,a:hover {
  color: white; 
}

.navbar{
  max-height: 125px;
  
}

.logo{
    max-width: 75%;
    max-height: 75%;
}

 /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }
</style>