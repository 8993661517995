<template>
 
    <footer> 
        
        <div class= "social-media-row text-center d-flex justify-content-center">
          <a href="https://www.facebook.com/rahsaanandhakeem/" target="_blank">
                <img  class="social-icons" alt="social-icons" src="../assets/facebook-icon.png">
                </a>
             <a href="https://music.apple.com/us/artist/rahsaan-hakeem/1529633574" target="_blank">
              <img  class="social-icons" alt="social-icons" src="../assets/applemusic-icon.png">
              </a>
             <a href="https://www.instagram.com/rahsaanandhakeem/" target="_blank">
                <img  class="social-icons" alt="social-icons" src="../assets/instagram-icon.png">

              </a>
              <a href="https://twitter.com/nofearonlyfaith?lang=en" target="_blank">
                <img  class="social-icons" alt="social-icons" src="../assets/twitter-icon.png">
                </a>
        </div>
        <p>© {{ new Date().getFullYear() }} Rahsaan&amp;Hakeem L.L.C.</p>
      </footer>
</template>

<script>

export default {
  name: "Mainfooter",
};
</script>

<style scoped>
 /* Footer */

footer{
    background: rgb(5, 5, 5); 
    padding: 0px 0px 0px 0px;
    color: white;
    font-size: 16px;
    margin-top: 200px; 
  }

  footer p{
  text-align: center;
  color:white;
  text-indent: 30px;
  }
  .social-media-row{
    display: flex;
    align-items: center;
  }
  .social-media-row a {
    width: 80%;
    margin: 10px auto; 
    max-height: 10rem;
    color:white;
    font-style: italic;
  }

img.social-icons{
    max-width: 10%;
    max-height: 10%;
} 

 /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }
</style>